import React from 'react';
import styles from './Header.module.scss';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false
        };
    }

    componentDidMount() {
        this.setState({active: false});
    }

    scrollToElement(id){
        window.scrollTo(0, document.getElementById(id).offsetTop - 100);
    }

    toggleHamburger(){
        this.setState({active: !this.state.active});
    }

    render() {
        return (
            <div className={styles.Header}>
                <div style={{float: "left"}}>
                    Alexandros Kalogerakis
                </div>

                <div className={styles.desktopMenu}>
                    <div onClick={()=>this.scrollToElement("contact")} className={styles.option}>
                        Contact
                    </div>
                    <div onClick={()=>this.scrollToElement("portfolio")} className={styles.option}>
                        Portfolio
                    </div>
                    <div onClick={()=>this.scrollToElement("skills")} className={styles.option}>
                        Skills
                    </div>
                    <div onClick={()=>this.scrollToElement("knowledge")} className={styles.option}>
                        Knowledge
                    </div>
                    <div onClick={()=>this.scrollToElement("about")} className={styles.option}>
                        About me
                    </div>
                </div>

                <div style={{transform: "translate("+(this.state.active?"0":"170") + "px, 0px)", transition: "all 0.3s ease-in-out", position: "fixed", top: "10px", right: "0px", width: "170px", padding: "50px 20px 20px 20px", backgroundColor: "white", borderRadius: "15px 0px 0px 15px", color: "rgb(22, 28, 45)"}}>
                    <div onClick={()=>this.scrollToElement("about")} className={styles.mobileOption}>
                        About me
                    </div>
                    <div onClick={()=>this.scrollToElement("knowledge")} className={styles.mobileOption}>
                        Knowledge
                    </div>
                    <div onClick={()=>this.scrollToElement("skills")} className={styles.mobileOption}>
                        Skills
                    </div>
                    <div onClick={()=>this.scrollToElement("portfolio")} className={styles.mobileOption}>
                        Portfolio
                    </div>
                    <div onClick={()=>this.scrollToElement("contact")} className={styles.mobileOption}>
                        Contact
                    </div>
                </div>
                <div className={styles.option + " " + styles.mobileMenu} style={{margin: "0px", filter: "drop-shadow(0px 0px 2px gray)"}}>
                    <div onClick={() => this.toggleHamburger()} className={"hamburger hamburger--collapse " + (this.state.active?"is-active":"")} type="button">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </div>
                </div>

            </div>
        );
    }
}
