import React from 'react';
import styles from './Knowledge.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faDesktop, faLayerGroup, faMobileAlt, faVideo } from '@fortawesome/free-solid-svg-icons'
import {faUbuntu, faUnity} from '@fortawesome/free-brands-svg-icons'
import {Col, Container, Row} from "reactstrap";

import { Icon, InlineIcon } from '@iconify/react';
import arduinoIcon from '@iconify-icons/logos/arduino';

export default class Knowledge extends React.Component {
    render() {
        return (
            <div style={{width: "100%"}}>
                <Container>
                    <div style={{marginBottom: "20px"}}>
                        <h1>Knowledge</h1>
                    </div>

                    <Row>
                        <Col md={6} lg={4} style={{marginBottom: "30px"}}>
                            <div className={"orange"} style={{fontSize: "20px", fontWeight: "bold"}}>
                                <FontAwesomeIcon icon={faLayerGroup} style={{marginRight: "15px"}}/>Full Stack Web Development
                            </div>
                            <div>
                                <br/>
                                I can develop both <b>client</b> and <b>server</b> side applications<br/>
                                <br/>
                                In the <b>Front End</b> I have experience with:<br/>
                                HTML, CSS, JavaScript, jQuery, Bootstrap, React<br/>
                                In the <b>Back End</b> I have experience with:<br/>
                                PHP, NodeJS, Java with Spring MVC, Python, MySQL, SQLite
                            </div>
                        </Col>
                        <Col md={6} lg={4} style={{marginBottom: "30px"}}>
                            <div className={"orange"} style={{fontSize: "20px", fontWeight: "bold"}}>
                                <FontAwesomeIcon icon={faUbuntu} style={{marginRight: "15px"}}/>System Administration
                            </div>
                            <div>
                                <br/>
                                I have a lot of experience managing websites through WHM/cPanel & Webmin/Virtualmin, as well as using the terminal in Linux environments
                            </div>
                        </Col>
                        <Col md={6} lg={4} style={{marginBottom: "30px"}}>
                            <div className={"orange"} style={{fontSize: "20px", fontWeight: "bold"}}>
                                <FontAwesomeIcon icon={faMobileAlt} style={{marginRight: "15px"}}/>Mobile Applications
                            </div>
                            <div>
                                <br/>
                                I have worked on several Native Mobile Applications for <b>Android</b> and <b>iOS</b>, using Java and Objective-C respectively<br/>
                                <br/>
                                I know how to generate signed builds and publish to Google Play and App Store
                                <br/>
                                I also have some experience using Phonegap
                            </div>
                        </Col>
                        <Col md={6} lg={3} style={{marginBottom: "30px"}}>
                            <div className={"orange"} style={{fontSize: "20px", fontWeight: "bold"}}>
                                <FontAwesomeIcon icon={faDesktop} style={{marginRight: "15px"}}/>Desktop Applications
                            </div>
                            <div>
                                <br/>
                                Using the <b>Electron</b> Framework, I have developed cross-platform applications with JavaScript, HTML, and CSS<br/>
                            </div>
                        </Col>
                        <Col md={4} lg={3} style={{marginBottom: "30px"}}>
                            <div className={"orange"} style={{fontSize: "20px", fontWeight: "bold"}}>
                                <FontAwesomeIcon icon={faUnity} style={{marginRight: "15px"}}/>Unity
                            </div>
                            <div>
                                <br/>
                                My programming journey started by making small games with Unity. I taught myself how to design and develop games, with multiplayer functionalities and backend databases
                            </div>
                        </Col>
                        <Col md={4}  lg={3} style={{marginBottom: "30px"}}>
                            <div className={"orange"} style={{fontSize: "20px", fontWeight: "bold"}}>
                                <Icon style={{}} icon={arduinoIcon} style={{marginRight: "15px"}}/>Arduino
                            </div>
                            <div>
                                <br/>
                                I'm good with electronics and designing circuits, as well as programming the Arduino and using sensors
                            </div>
                        </Col>
                        <Col md={4} lg={3} style={{marginBottom: "30px"}}>
                            <div className={"orange"} style={{fontSize: "20px", fontWeight: "bold"}}>
                                <FontAwesomeIcon icon={faVideo} style={{marginRight: "15px"}}/>Photo / Video Editing
                            </div>
                            <div>
                                <br/>
                                While developing websites/apps/games, there was a necessity to learn how to edit photos and videos
                                <br/>
                                I also know how to use OBS and stream to any RTMP server
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
