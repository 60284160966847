import React from 'react';
import styles from './PortfolioItem.module.scss';
import {Col, Container, Row} from "reactstrap";

export default class PortfolioItem extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            visible: false,
            scale: 1,
            position: "initial",
            top: 0,
            width: 0,
            height: "auto",
            left: 0,
        };
    }

    componentDidMount() {
        let visible = false;
        this.props.tags.forEach(tag => {
            if(tag == this.props.active || this.props.active == "All")
                visible = true;
        });
        this.setState({visible: visible, scale: visible?1:0, position: visible?"initial":"absolute"});
        this.setState({top: this.ref.current.parentElement.offsetTop, height: "auto", width: this.ref.current.parentElement.offsetWidth, left: this.ref.current.parentElement.offsetLeft});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.active != this.props.active) {
            let visible = false;
            this.props.tags.forEach(tag => {
                if (tag == this.props.active || this.props.active == "All")
                    visible = true;
            });
            if (visible != this.state.visible) {
                if(visible){
                    setTimeout(()=>{
                        this.setState({visible: true, position: "initial", zIndex: 0});
                        setTimeout(()=>{
                            this.setState({top: this.ref.current.parentElement.offsetTop, height: "auto", width: this.ref.current.parentElement.offsetWidth, left: this.ref.current.parentElement.offsetLeft, scale: 1});
                        },1);
                    },100);
                }
                else{
                    this.setState({top: this.ref.current.parentElement.offsetTop, height: this.ref.current.parentElement.offsetHeight, width: this.ref.current.parentElement.offsetWidth, left: this.ref.current.parentElement.offsetLeft, scale: 0, position: "absolute", zIndex: 1});
                    setTimeout(()=>{
                        this.setState({visible: false});
                    }, 300)
                }
            }
        }
    }

    render() {
        return (
            <Col xs={12} sm={6} lg={3} style={{display: this.state.visible?"block":"block", opacity: this.state.visible?1:0, width: this.state.width, height: this.state.height, left: this.state.left, top: this.state.top, position: this.state.position, transition: "all 0.3s ease-in-out", transform: "scale("+this.state.scale+")"}}>
                <div onClick={this.props.onClick} ref={this.ref} style={{cursor: "pointer", color: "black", marginTop: "20px", height: "calc(100% - 20px)"}}>
                    <div style={{backgroundColor: "white", padding: "10px 10px 35px 10px", height: "100%", position: "relative"}}>
                        <div style={{width: "100%", height: "150px"}}>
                            <img className={"zoom"} src={this.props.image} style={{backgroundColor: "white", width: "100%", height: "100%", objectFit: "cover"}}/>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <b>
                                {this.props.title}
                            </b>
                        </div>
                        <div style={{marginTop: "0px", minHeight: "48px"}}>
                            <small className={"orange"}>
                                {this.props.tags.join(" | ")}
                            </small>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            {this.props.description}
                        </div>
                        <div style={{marginTop: "10px", position: "absolute", left: "10px", bottom: "5px"}}>
                            <small className={"orange"}>
                                {this.props.year}
                            </small>
                        </div>
                    </div>
                </div>
            </Col>
        );
    }
}
