import React from 'react';
import styles from './Home.module.scss';
import Welcome from '../Welcome/Welcome';
import Knowledge from '../Knowledge/Knowledge';

import { Container, Row, Col } from 'reactstrap';

import Skills from "../Skills/Skills";
import Portfolio from "../Portfolio/Portfolio";
import Contact from "../Contact/Contact";

export default class Home extends React.Component {
    render() {
        return (
            <div>
                <Container>
                    <div id="about" style={{justifyContent: "center", textAlign: "center", width: "100%"}}>
                        <Welcome/>
                    </div>
                    <Row style={{marginTop: "50px"}}>
                        <div id="knowledge" style={{marginTop: "50px", width: "100%"}}>
                            <Knowledge/>
                        </div>
                        <div id="skills" style={{marginTop: "50px", width: "100%"}}>
                            <Skills/>
                        </div>
                        <div id="portfolio" style={{marginTop: "50px", width: "100%"}}>
                            <Portfolio/>
                        </div>
                        <div id="contact" style={{marginTop: "50px", width: "100%"}}>
                            <Contact/>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
}
