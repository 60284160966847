import React from 'react';
import styles from './Skills.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import {Col, Container, Row} from "reactstrap";
import Skill from "./Skill";

export default class Skills extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMore: false,
            showMoreText: "Show more"
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={{width: "100%"}}>
                <Container>
                    <h1 style={{marginBottom: "20px"}}>Skills</h1>
                    <Row>
                        <Skill visible={true} title={"Git"} percentage={77}/>
                        <Skill visible={true} title={"HTML"} percentage={95}/>
                        <Skill visible={true} title={"JavaScript"} percentage={90}/>
                        <Skill visible={true} title={"CSS / SCSS"} percentage={87}/>
                        <Skill visible={true} title={"jQuery"} percentage={82}/>
                        <Skill visible={true} title={"Bootstrap"} percentage={83}/>
                        <Skill visible={true} title={"React"} percentage={76}/>
                        <Skill visible={true} title={"PHP"} percentage={84}/>
                        <Skill visible={true} title={"Wordpress"} percentage={82}/>
                        <Skill visible={true} title={"NodeJS"} percentage={80}/>
                        <Skill visible={true} title={"Websocket"} percentage={90}/>
                        <Skill visible={true} title={"MySQL / SQLite"} percentage={92}/>
                        <Skill visible={this.state.showMore} title={"Typescript"} percentage={72}/>
                        <Skill visible={this.state.showMore} title={"SharePoint (Managing sites & Developing webparts)"} percentage={83}/>
                        <Skill visible={this.state.showMore} title={"Unity (C#)"} percentage={82}/>
                        <Skill visible={this.state.showMore} title={"Arduino"} percentage={87}/>
                        <Skill visible={this.state.showMore} title={"Java (Back End)"} percentage={66}/>
                        <Skill visible={this.state.showMore} title={"Python"} percentage={64}/>
                        <Skill visible={this.state.showMore} title={"Java (Android)"} percentage={78}/>
                        <Skill visible={this.state.showMore} title={"Objective-C"} percentage={65}/>
                        <Skill visible={this.state.showMore} title={"Phonegap"} percentage={63}/>
                        <Skill visible={this.state.showMore} title={"C"} percentage={70}/>
                        <Skill visible={this.state.showMore} title={"C++"} percentage={60}/>
                        <Skill visible={this.state.showMore} title={"Adobe Photoshop"} percentage={83}/>
                        <Skill visible={this.state.showMore} title={"Adobe After Effects"} percentage={78}/>
                        <Skill visible={this.state.showMore} title={"Cinema4D"} percentage={68}/>
                        <Skill visible={this.state.showMore} title={"OBS"} percentage={86}/>
                        <Skill visible={this.state.showMore} title={"Microsoft Office"} percentage={90}/>
                    </Row>
                    <div style={{cursor: "pointer"}} onClick={() => this.ShowMore()}>
                        {this.state.showMoreText}
                    </div>
                </Container>
            </div>
        );
    }

    async ShowMore(){
        await this.setState({showMore: !this.state.showMore});
        this.setState({showMoreText: this.state.showMore?"Show less":"Show more"})
    }
}
