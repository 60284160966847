import React from 'react';
import styles from './Portfolio.module.scss';
import {Col, Container, Row} from "reactstrap";
import PortfolioItem from "./PortfolioItem";

import friendometer from "../images/friendometerThumbnail.png"
import friendometer1 from "../images/friendometer1.jpg"
import friendometer2 from "../images/friendometer2.jpg"

import chitchatting from "../images/chitchatting.png"

import fndisplay from "../images/fndisplay.png"
import fndisplayVideo from "../videos/fndisplay.mp4"

import fnnetwork from "../images/fnnetwork.png"
import fnnetworkVideo from "../videos/fnnetwork.mp4"

import fniris from "../images/fniris.png"
import fniris1 from "../images/fniris1.png"
import fniris2 from "../images/fniris2.png"
import fniris3 from "../images/fniris3.png"
import fniris4 from "../images/fniris4.png"

import rollit from "../images/rollit.png"
import reachit from "../images/reachit.png"
import marchingdead from "../images/marchingdead.png"
import magicalfunpark from "../images/magicalfunpark.jpg"
import frontsight from "../images/frontsight.png"
import quoridor from "../images/quoridor.png"
import kapow from "../images/kapow.png"
import kart from "../images/kart.png"
import driving from "../images/driving.png"

import joystick from "../images/joystick.png"
import handgun from "../images/handgun.png"
import stereovolume from "../images/stereovolume.png"
import raspberrycontroller from "../images/raspberrycontroller.png"

import vrcontroller from "../images/vrcontroller.png"
import vrcontrollerVideo from "../videos/controller.mp4"

import bluetoothcontroller from "../images/bluetoothcontroller.png"

import remoteslider from "../images/remoteslider.png"
import remotesliderVideo from "../videos/remoteslider.mp4"

import tagme from "../images/tagme.png"
import tagme1 from "../images/tagme1.jpg"
import tagme2 from "../images/tagme2.jpg"
import tagme3 from "../images/tagme3.jpg"
import tagme4 from "../images/tagme4.jpg"

import pwa from "../images/pwa.jpg"
import pwa0 from "../images/pwa0.jpg"
import pwa1 from "../images/pwa1.jpg"
import pwa2 from "../images/pwa2.jpg"
import pwa3 from "../images/pwa3.jpg"

import oncology from "../images/oncology.jpg"
import oncology1 from "../images/oncology1.jpg"
import oncology2 from "../images/oncology2.jpg"
import oncology3 from "../images/oncology3.jpg"
import oncology4 from "../images/oncology4.jpg"
import oncology5 from "../images/oncology5.jpg"

import cf from "../images/cf.jpg"
import cf1 from "../images/cf1.jpg"
import cf2 from "../images/cf2.jpg"
import cf3 from "../images/cf3.jpg"
import cf4 from "../images/cf4.jpg"

import mconferences from "../images/mconferences.jpg"
import mconferences1 from "../images/mconferences1.jpg"
import mconferences2 from "../images/mconferences2.jpg"
import mconferences3 from "../images/mconferences3.jpg"
import mconferences4 from "../images/mconferences4.jpg"

import african from "../images/african.jpg"
import african1 from "../images/african1.jpg"
import african2 from "../images/african2.jpg"
import african3 from "../images/african3.jpg"
import african4 from "../images/african4.jpg"

import sharepoint from "../images/sharepoint.png"
import sharepoint1 from "../images/sharepoint1.png"
import sharepoint2 from "../images/sharepoint2.png"
import sharepoint3 from "../images/sharepoint3.png"
import sharepoint4 from "../images/sharepoint4.png"
import sharepoint5 from "../images/sharepoint5.png"

import livestream from "../images/livestream.png"

import portfolio from "../images/portfolio.jpg"

import PortfolioCarousel from "./PortfolioCarousel";

export default class Portfolio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: "",
            detailsModal: false,
            detailsTitle: "",
            detailsDescription: "",
            detailsMedia: []
        };
    }

    componentDidMount() {
        this.setState({active: ""});
        setTimeout(()=>{
            this.setState({active: "Featured"});
        },500)
    }

    Select(tag){
        this.setState({active: tag});
    }

    PortfolioClicked(title, media, desc){
        this.setState({detailsModal: true, detailsMedia: media, detailsTitle: title, detailsDescription: desc});
    }

    render() {
        return (
            <div style={{width: "100%"}}>

                <div style={{display: this.state.detailsModal?"block":"none"}}>
                    <div onClick={() => this.setState({ detailsModal: false })} style={{position: "fixed", left: "0px", right: "0px", top: "0px", bottom: "0px", backgroundColor: "rgba(0,0,0,0.5)", zIndex: 10}}>
                        <div onClick={(e) => e.stopPropagation()} className={styles.box} style={{borderRadius: "5px", transform: "translate(0%, -50%)", color: "black", position: "fixed", padding: "15px 30px", left: "0px", top: "50%", width: "100%", height: "500px", maxHeight: "95%", backgroundColor: "white", zIndex: 11}}>
                            <div className={styles.row + " " + styles.header + " orange"}>
                                <big><b>{this.state.detailsTitle}</b></big>
                            </div>
                            <div>
                            <hr/>
                            </div>
                            <div className={styles.row + " " + styles.content}>
                                <Container fluid={true} style={{height: "100%"}}>
                                    <Row style={{height: "100%"}}>
                                        <Col md={6} className={styles.modalContent}>
                                            <PortfolioCarousel visible={this.state.detailsModal} media={this.state.detailsMedia}/>
                                        </Col>
                                        <Col md={6} className={styles.modalContent} style={{overflow: "auto"}}>
                                            <div dangerouslySetInnerHTML={{__html: this.state.detailsDescription}}/>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>
                </div>

                <Container>
                    <div style={{marginBottom: "20px"}}>
                        <h1>Portfolio</h1>
                    </div>
                    <div style={{fontWeight: "bold"}}>
                        <span onClick={()=> this.Select("Featured")} className={styles.tag} style={{whiteSpace: "nowrap", color: this.state.active=="Featured"?"#f99429":"white"}}>Featured</span> |&nbsp;
                        <span onClick={()=> this.Select("Websites")} className={styles.tag} style={{whiteSpace: "nowrap", color: this.state.active=="Websites"?"#f99429":"white"}}>Websites</span> |&nbsp;
                        <span onClick={()=> this.Select("Websocket")} className={styles.tag} style={{whiteSpace: "nowrap", color: this.state.active=="Websocket"?"#f99429":"white"}}>Websocket</span> |&nbsp;
                        <span onClick={()=> this.Select("Mobile Apps")} className={styles.tag} style={{whiteSpace: "nowrap", color: this.state.active=="Mobile Apps"?"#f99429":"white"}}>Mobile Apps</span> |&nbsp;
                        <span onClick={()=> this.Select("Chrome Extensions")} className={styles.tag} style={{whiteSpace: "nowrap", color: this.state.active=="Chrome Extensions"?"#f99429":"white"}}>Chrome Extensions</span> |&nbsp;
                        <span onClick={()=> this.Select("Electron")} className={styles.tag} style={{whiteSpace: "nowrap", color: this.state.active=="Electron"?"#f99429":"white"}}>Electron</span> |&nbsp;
                        <span onClick={()=> this.Select("Arduino")} className={styles.tag} style={{whiteSpace: "nowrap", color: this.state.active=="Arduino"?"#f99429":"white"}}>Arduino</span> |&nbsp;
                        <span onClick={()=> this.Select("Games")} className={styles.tag} style={{whiteSpace: "nowrap", color: this.state.active=="Games"?"#f99429":"white"}}>Games</span> |&nbsp;
                        <span onClick={()=> this.Select("All")} className={styles.tag} style={{whiteSpace: "nowrap", color: this.state.active=="All"?"#f99429":"white"}}>All</span>
                    </div>
                    <Row style={{position: "relative", minHeight: "350px"}}>
                        <PortfolioItem
                            image={portfolio}
                            active={this.state.active}
                            tags={["Featured", "Websites"]}
                            title={"My Portfolio"}
                            description={"Portfolio website developed with React, managed with Virtualmin/Webmin"}
                            year={"2021"}
                            onClick={() => this.PortfolioClicked(
                                "My Portfolio",
                                [
                                    {src: portfolio, altText: "", caption: "", type: "img"}
                                ],
                                "<p>For my portfolio I used React in the frontend. For the Contact Form PHP and MySQL are used to store the data of users who submit it.</p>" +
                                "<p>The website is hosted in a server in which I use Virtualmin/Webmin to manage it.</p>"
                            )}
                        />
                        <PortfolioItem
                            image={livestream}
                            active={this.state.active}
                            tags={["Featured", "Websites", "Websocket"]}
                            title={"Livestreaming with polling"}
                            description={"Custom-made website with embedded youtube livestream and popup polling questions by Slido, triggered by Websocket messages"}
                            year={"2021"}
                            onClick={() => this.PortfolioClicked(
                                "Livestreaming with polling",
                                [
                                    {src: livestream, altText: "", caption: "", type: "img"}
                                ],
                                "<p>The requirement for this project was that the webinar had to be interactive. For that reason, Youtube was used for the livestreaming, as it offers ultra low latency streaming (around 4 seconds), and Slido for the polling questions.</p>" +
                                "<p>A simple control panel was made to \"push\" the popup to all connected users at the same time. Behind the scenes, a Websocket server written in NodeJS was listening to events from the control panel, and when it was time to show the modal, a special message was sent to all the users, which made an iframe appear in front of them, with the correct URL linking to Slido's event.</p>"
                            )}
                        />
                        <PortfolioItem
                            image={tagme}
                            active={this.state.active}
                            tags={["Chrome Extensions"]}
                            title={"TagME"}
                            description={"\"Tag\" the current website to publish & promote the event on mConferences"}
                            year={2021}
                            onClick={() => this.PortfolioClicked(
                                "TagME Chrome Extension",
                                [
                                    {src: tagme, altText: "", caption: "", type: "img"},
                                    {src: tagme1, altText: "", caption: "", type: "img"},
                                    {src: tagme2, altText: "", caption: "", type: "img"},
                                    {src: tagme3, altText: "", caption: "", type: "img"},
                                    {src: tagme4, altText: "", caption: "", type: "img"}
                                ],
                                "<p>Users can \"Tag\" the current website, which will send a POST request to an API that I built, in order for the website to be stored in a remote database.</p>" +
                                "<p>At the same time, the page is stored in the user's local storage, and is displayed in a table built using Datatables.</p>" +
                                "<p>The \"Tagged\" websites are then manually reviewed and published on <a href='https://mConferences.com' target='_blank'>mConferences.com</a>.</p>" +
                                "<p>The Extension is available in the <a href='https://chrome.google.com/webstore/detail/tagme/ijfagkhfkpdgcnchaelmlaieechajaek?hl=en' target='_blank'>Chrome Web Store</a></p>"
                            )}
                        />
                        <PortfolioItem
                            image={remoteslider}
                            active={this.state.active}
                            tags={["Featured", "Chrome Extensions", "Websocket", "Electron"]}
                            title={"Remote Slider"}
                            description={"Control a powerpoint presentation and move a laser pointer, all remotely inside of Streamyard"}
                            year={2021}
                            onClick={() => this.PortfolioClicked(
                                "Remote Slider Extension + Host App",
                                [
                                    {src: remoteslider, altText: "", caption: "", type: "img"},
                                    {src: remotesliderVideo, altText: "", caption: "", type: "video"}
                                ],
                                "<p>This extension was developed in order to make every presenter's live easier. At M-Data we use Streamyard to host our webinars, and the requirement was for the speaker to be able to control his powerpoint presentation, without sharing his own screen. The process is split into three parts, the Chrome Extension, an Electron app and an intermediate Websocket server written in NodeJS.</p>" +
                                "<p>Firstly, the user who is responsible to share the powerpoint presentation must install an Electron app, which communicates with the Websocket server. The Electron app handles showing a laser pointer on the screen, and also changing the powerpoint slides.</p>" +
                                "<p>The Chrome Extension is responsible to capture the speaker's keyboard arrow keys (left/right), and also the position of the mouse cursor, relative to the shared video.</p>" +
                                "<p>The Websocket server is responsible to send messages from the speaker to the Electron app.</p>"
                            )}
                        />
                        <PortfolioItem
                            image={fndisplay}
                            active={this.state.active}
                            tags={["Featured", "Electron", "Websocket"]}
                            title={"FN Display"}
                            description={"Remote desktop application using WebRTC and Websocket communication"}
                            year={"2021 - ..."}
                            onClick={() => this.PortfolioClicked(
                                "FN Display Remote Desktop Application",
                                [
                                    {src: fndisplay, altText: "", caption: "", type: "img"},
                                    {src: fndisplayVideo, altText: "", caption: "", type: "video"}
                                ],
                                "<p>This Electron app is a remote desktop application, which uses WebRTC to stream the screen of the user, and Websocket for the cursor movement and chatting functionality. Furthermore, the app supports synchronizing clipboard's, multiple connections at the same time (inbound and outbound), and voice communication. Lastly, the user can set up a password for unattended access to his device.<br/>For the peer-to-peer connection, the PeerJS library is being used.</p>" +
                                "<p>The App currently works on Windows, Mac OS, Linux, and a browser viewer-only version is under development.</p>"
                            )}
                        />
                        <PortfolioItem
                            image={sharepoint}
                            active={this.state.active}
                            tags={["Featured", "Websites"]}
                            title={"SharePoint Website"}
                            description={"Custom-made website for SharePoint, with webparts written in SPFx and React"}
                            year={"2020 - 2021"}
                            onClick={() => this.PortfolioClicked(
                                "SharePoint Website",
                                [
                                    {src: sharepoint, altText: "", caption: "", type: "img"},
                                    {src: sharepoint1, altText: "", caption: "", type: "img"},
                                    {src: sharepoint2, altText: "", caption: "", type: "img"},
                                    {src: sharepoint3, altText: "", caption: "", type: "img"},
                                    {src: sharepoint4, altText: "", caption: "", type: "img"},
                                    {src: sharepoint5, altText: "", caption: "", type: "img"}
                                ],
                                "<p>This SharePoint Intranet site was built for a client completely from scratch. The whole website uses custom webparts written in SPFx and React. Additional libraries used are ChartJS, jQuery Datatables and PptxGenJS.</p>" +
                                "<p>The purpose of this site is to allow users to easily create Actions, Activities, Meetings etc. with the use of forms, and show them in tables.</p>" +
                                "<p>Lastly, the contents of each table can be exported in various formats, i.e. XLSX, PPTX, PDF, or Print them.</p>"
                            )}
                        />
                        <PortfolioItem
                            image={pwa}
                            active={this.state.active}
                            tags={["Featured", "Websites"]}
                            title={"mConferences PWA"}
                            description={"Website and PWA to find medical events in Greece & Europe from a huge database"}
                            year={"2020 - ..."}
                            onClick={() => this.PortfolioClicked(
                            "mConferences PWA",
                            [
                                {src: pwa, altText: "", caption: "", type: "img"},
                                {src: pwa0, altText: "", caption: "", type: "img"},
                                {src: pwa1, altText: "", caption: "", type: "img"},
                                {src: pwa2, altText: "", caption: "", type: "img"},
                                {src: pwa3, altText: "", caption: "", type: "img"}
                            ],
                            "<p>This is the new version of the original mConferences native application. It uses one of the largest and most up to date Medical Conference database in Greece & Europe. The site supports localization in both the english and greek languages, advanced search functionalities, and a calendar view.</p>" +
                                "<p>The site was developed with no frameworks, and uses PHP and MySQL in the backend and can be found <a href='https://pwa.mconferences.com/' target='_blank'>here</a>.</p>"
                        )}
                        />
                        <PortfolioItem
                            image={fnnetwork}
                            active={this.state.active}
                            tags={["Featured", "Websocket"]}
                            title={"FN Network"}
                            description={"Networking solution to make multiplayer games, currently under development for Unity"}
                            year={"2020 - ..."}
                            onClick={() => this.PortfolioClicked(
                                "FN Network - Networking solution for game developers",
                                [
                                    {src: fnnetwork, altText: "", caption: "", type: "img"},
                                    {src: fnnetworkVideo, altText: "", caption: "", type: "video"}
                                ],
                                "<p>FN Network is a networking solution that helps developers add multiplayer functionalities to their games, without the need for them to program and host them.</p>" +
                                "<p>On the backend it uses a Websocket and a UDP server written in NodeJS, which supports multiple developers with multiple games and many connected users at the same time. All messages sent from and to the server are optimized as much as possible, and they are in bytes format, instead of JSON, so it uses much less bandwidth.</p>" +
                                "<p>Currently the SDK version for Unity is being built and tested, and cross-platform development is allowed. Windows users can play with Mac OS, and even WebGL (browser) users. When the Unity SDK is completed, it will be ported to other big game engines like Unreal and Unigine.</p>"
                            )}
                        />
                        <PortfolioItem
                            image={friendometer}
                            active={this.state.active}
                            tags={["Featured", "Websites"]}
                            title={"Friendometer"}
                            description={"Quiz maker to see which friend knows you the best"}
                            year={2020}
                            onClick={() => this.PortfolioClicked(
                                "Friendometer",
                                [
                                    {src: friendometer, altText: "", caption: "", type: "img"},
                                    {src: friendometer1, altText: "", caption: "", type: "img"},
                                    {src: friendometer2, altText: "", caption: "", type: "img"}
                                ],
                                "<p>In <a href='https://friendometerapp.com' target='_blank'>friendometerapp.com</a> you can create your own quiz, with every question and answer being customizable, and share your personalized quiz link with friends to see who knows you the best.</p>" +
                                "<p>It uses PHP and MySQL to store the quizzes and the user answers, and displays a leaderboard with your top friends. You can even check each friend's individual answers.</p>"
                            )}
                        />
                        <PortfolioItem
                            image={african}
                            active={this.state.active}
                            tags={["Mobile Apps"]}
                            title={"African Awards"}
                            description={"Mobile Application for the African Awards produced by Mshale Newspaper"}
                            year={"2019"}
                            onClick={() => this.PortfolioClicked(
                                "African Awards",
                                [
                                    {src: african, altText: "", caption: "", type: "img"},
                                    {src: african1, altText: "", caption: "", type: "img"},
                                    {src: african2, altText: "", caption: "", type: "img"},
                                    {src: african3, altText: "", caption: "", type: "img"},
                                    {src: african4, altText: "", caption: "", type: "img"},
                                ],
                                "<p>This app was developed to show all the information needed about the African Awards produced by Mshale Newspaper including nominee bios and the African Awards Gala.</p>" +
                                "<p>A complete admin panel was also developed, where the responsible person can insert data easily and add new events at any time.</p>" +
                                "<p>The app is written in Java for Android, and Objective-C for iOS, and can be found in the <a href='https://play.google.com/store/apps/details?id=com.mdata.africanawards' target='_blank'>Google Play</a> and the <a href='https://apps.apple.com/us/app/african-awards/id1482171425' target='_blank'>App Store</a>.<br/>The backend was developed using HTML, CSS, vanilla Javascript, and PHP, MySQL in the backend.</p>"
                            )}
                        />
                        <PortfolioItem
                            image={kart}
                            active={this.state.active}
                            tags={["Games"]}
                            title={"Kart Time Trial"}
                            description={"Multiplayer Kart simulation"}
                            year={2019}
                            onClick={() => this.PortfolioClicked(
                                "Kart Time Trial",
                                [
                                    {src: kart, altText: "", caption: "", type: "img"}
                                ],
                                "Multiplayer Kart simulation"
                            )}
                        />
                        <PortfolioItem
                            image={vrcontroller}
                            active={this.state.active}
                            tags={["Featured", "Arduino"]}
                            title={"Motion Controller"}
                            description={"SteamVR Motion Controller for my Headset using OpenCV"}
                            year={2019}
                            onClick={() => this.PortfolioClicked(
                                "Motion Controller",
                                [
                                    {src: vrcontroller, altText: "", caption: "", type: "img"},
                                    {src: vrcontrollerVideo, altText: "", caption: "", type: "video"}
                                ],
                                "SteamVR Motion Controller for my Headset using OpenCV"
                            )}
                        />
                        <PortfolioItem
                            image={cf}
                            active={this.state.active}
                            tags={["Featured", "Mobile Apps"]}
                            title={"CF MedCare"}
                            description={"App designed to help patients with Cystic Fibrosis with their therapeutic regime"}
                            year={"2018 - ..."}
                            onClick={() => this.PortfolioClicked(
                                "CF MedCare Reminder App",
                                [
                                    {src: cf, altText: "", caption: "", type: "img"},
                                    {src: cf1, altText: "", caption: "", type: "img"},
                                    {src: cf2, altText: "", caption: "", type: "img"},
                                    {src: cf3, altText: "", caption: "", type: "img"},
                                    {src: cf4, altText: "", caption: "", type: "img"}
                                ],
                                "<p>CF MedCare is a reminder app in which patients can insert data about their medicine and set reminders, and I've been maintaining it since 2018.</p>" +
                                "<p>The app is written in Java for Android, and Objective-C for iOS, and can be found in the <a href='https://play.google.com/store/apps/details?id=nl.cone.cfmedcare' target='_blank'>Google Play</a> and the <a href='https://apps.apple.com/gr/app/cf-medcare/id658766860' target='_blank'>App Store</a>.</p>"
                            )}
                        />
                        <PortfolioItem
                            image={oncology}
                            active={this.state.active}
                            tags={["Featured", "Mobile Apps"]}
                            title={"mGuides Oncology"}
                            description={"mGuides Oncology & Hematology is a multi-event app for medical conferences referring to oncologists and hematologists"}
                            year={"2018 - ..."}
                            onClick={() => this.PortfolioClicked(
                                "mGuides Oncology & Hematology",
                                [
                                    {src: oncology, altText: "", caption: "", type: "img"},
                                    {src: oncology1, altText: "", caption: "", type: "img"},
                                    {src: oncology2, altText: "", caption: "", type: "img"},
                                    {src: oncology3, altText: "", caption: "", type: "img"},
                                    {src: oncology4, altText: "", caption: "", type: "img"},
                                    {src: oncology5, altText: "", caption: "", type: "img"}
                                ],
                                "<p>mGuides Oncology & Hematology is a multi-event app for medical conferences referring to oncologists and hematologists, which are held in Greece and abroad.</p>" +
                                "<p>With the app, Healthcare Professionals and Pharmaceutical Companies can easily find complete and accurate conferences information of their interest, in specific cities/countries and dates.</p>" +
                                "<p>A complete admin panel was also developed, where the responsible person can insert data easily and add new events at any time.</p>" +
                                "<p>The app is written in Java for Android, and Objective-C for iOS, and can be found in the <a href='https://play.google.com/store/apps/details?id=com.mdata.multieventapponcology' target='_blank'>Google Play</a> and the <a href='https://apps.apple.com/gr/app/mguides-oncology/id1377683885' target='_blank'>App Store</a>.<br/>The backend was developed using HTML, CSS, vanilla Javascript, and PHP, MySQL in the backend.</p>"
                            )}
                        />
                        <PortfolioItem
                            image={mconferences}
                            active={this.state.active}
                            tags={["Featured", "Mobile Apps"]}
                            title={"mConferences"}
                            description={"mConferences App is a mobile application for medical conferences"}
                            year={"2018 - ..."}
                            onClick={() => this.PortfolioClicked(
                                "mConferences",
                                [
                                    {src: mconferences, altText: "", caption: "", type: "img"},
                                    {src: mconferences1, altText: "", caption: "", type: "img"},
                                    {src: mconferences2, altText: "", caption: "", type: "img"},
                                    {src: mconferences3, altText: "", caption: "", type: "img"},
                                    {src: mconferences4, altText: "", caption: "", type: "img"}
                                ],
                                "<p>This is the original mConferences application. It uses one of the largest and most up to date Medical Conference database in Greece & Europe. The app supports advanced search functionalities, a calendar view, and offline navigation.</p>" +
                                "<p>The app was developed using Phonegap, and is now maintained by me. It can be found in the <a href='https://play.google.com/store/apps/details?id=gr.phonegap.mconferences' target='_blank'>Google Play</a> and the <a href='https://apps.apple.com/gr/app/mconferences/id1087000289' target='_blank'>App Store</a>.</p>"
                            )}
                        />
                        <PortfolioItem
                            image={chitchatting}
                            active={this.state.active}
                            tags={["Featured", "Websites"]}
                            title={"ChitChatting"}
                            description={"Chatting website to talk with friends"}
                            year={2018}
                            onClick={() => this.PortfolioClicked(
                                "ChitChatting",
                                [
                                    {src: chitchatting, altText: "", caption: "", type: "img"}
                                ],
                                "Chatting website to talk with friends"
                            )}
                        />
                        <PortfolioItem
                            image={driving}
                            active={this.state.active}
                            tags={["Games"]}
                            title={"Driving Game"}
                            description={"Singleplayer racing game"}
                            year={2018}
                            onClick={() => this.PortfolioClicked(
                                "Driving Game",
                                [
                                    {src: driving, altText: "", caption: "", type: "img"}
                                ],
                                "Singleplayer racing game"
                            )}
                        />

                        <PortfolioItem
                            image={raspberrycontroller}
                            active={this.state.active}
                            tags={["Arduino"]}
                            title={"Raspberry Pi Zero inside a controller"}
                            description={"RetroPie Emulators in a standalone controller"}
                            year={"2018"}
                            onClick={() => this.PortfolioClicked(
                                "Raspberry Pi Zero inside a controller",
                                [
                                    {src: raspberrycontroller, altText: "", caption: "", type: "img"}
                                ],
                                "RetroPie Emulators in a standalone controller"
                            )}
                        />
                        <PortfolioItem
                            image={fniris}
                            active={this.state.active}
                            tags={["Featured", "Arduino"]}
                            title={"FN Iris"}
                            description={"An entry-level, affordable, homemade, 3DoF SteamVR-compatible headset"}
                            year={"2017 - ..."}
                            onClick={() => this.PortfolioClicked(
                                "FN Iris",
                                [
                                    {src: fniris, altText: "", caption: "", type: "img"},
                                    {src: fniris1, altText: "", caption: "", type: "img"},
                                    {src: fniris2, altText: "", caption: "", type: "img"},
                                    {src: fniris3, altText: "", caption: "", type: "img"},
                                    {src: fniris4, altText: "", caption: "", type: "img"}
                                ],
                                "<p>An entry-level, affordable, homemade, 3DoF SteamVR-compatible headset. Uses 3D printed parts that I designed and printed, as well as two high resolution and high refresh rate displays. With a resolution of 2880x1440, each eye gets 1440x1440 pixels, at 120Hz.</p>"+
                                "<p>More info and videos can be found at my <a href='https://fniris.com' target='_blank'>website</a>.</p>"
                            )}
                        />

                        <PortfolioItem
                            image={kapow}
                            active={this.state.active}
                            tags={["Games"]}
                            title={"Ka-Pow! 3D"}
                            description={"Multiplayer game based on Bomberman"}
                            year={"2017"}
                            onClick={() => this.PortfolioClicked(
                                "Ka-Pow! 3D",
                                [
                                    {src: kapow, altText: "", caption: "", type: "img"}
                                ],
                                "Multiplayer game based on Bomberman"
                            )}
                        />
                        <PortfolioItem
                            image={quoridor}
                            active={this.state.active}
                            tags={["Games"]}
                            title={"Quoridor 3D"}
                            description={"Personal multiplayer project of the famous board game"}
                            year={"2017"}
                            onClick={() => this.PortfolioClicked(
                                "Quoridor 3D",
                                [
                                    {src: quoridor, altText: "", caption: "", type: "img"}
                                ],
                                "Personal multiplayer project of the famous board game"
                            )}
                        />
                        <PortfolioItem
                            image={bluetoothcontroller}
                            active={this.state.active}
                            tags={["Arduino"]}
                            title={"Bluetooth Controller"}
                            description={"Custom-made bluetooth controller for Windows"}
                            year={"2017"}
                            onClick={() => this.PortfolioClicked(
                                "Bluetooth Controller",
                                [
                                    {src: bluetoothcontroller, altText: "", caption: "", type: "img"}
                                ],
                                "Custom-made bluetooth controller for Windows"
                            )}
                        />
                        <PortfolioItem
                            image={stereovolume}
                            active={this.state.active}
                            tags={["Arduino"]}
                            title={"Stereo Volume Control Box"}
                            description={"Made to control the volume of any speaker/headset easily"}
                            year={"2017"}
                            onClick={() => this.PortfolioClicked(
                                "Stereo Volume Control Box",
                                [
                                    {src: stereovolume, altText: "", caption: "", type: "img"}
                                ],
                                "Made to control the volume of any speaker/headset easily"
                            )}
                        />
                        <PortfolioItem
                            image={handgun}
                            active={this.state.active}
                            tags={["Arduino"]}
                            title={"Gyroscope Handgun"}
                            description={"Handgun for custom-made games using an IMU"}
                            year={"2017"}
                            onClick={() => this.PortfolioClicked(
                                "Gyroscope Handgun",
                                [
                                    {src: handgun, altText: "", caption: "", type: "img"}
                                ],
                                "Handgun for custom-made games using an IMU"
                            )}
                        />
                        <PortfolioItem
                            image={joystick}
                            active={this.state.active}
                            tags={["Arduino"]}
                            title={"Arcade Controller"}
                            description={"Bluetooth arcade-style controller"}
                            year={"2017"}
                            onClick={() => this.PortfolioClicked(
                                "Arcade Controller",
                                [
                                    {src: joystick, altText: "", caption: "", type: "img"}
                                ],
                                "Bluetooth arcade-style controller"
                            )}
                        />
                        <PortfolioItem
                            image={frontsight}
                            active={this.state.active}
                            tags={["Games"]}
                            title={"Front Sight"}
                            description={"Multiplayer first person shooter game"}
                            year={"2015 - 2016"}
                            onClick={() => this.PortfolioClicked(
                                "Front Sight",
                                [
                                    {src: frontsight, altText: "", caption: "", type: "img"}
                                ],
                                "Multiplayer first person shooter game"
                            )}
                        />
                        <PortfolioItem
                            image={magicalfunpark}
                            active={this.state.active}
                            tags={["Games"]}
                            title={"Magical Fun Park Online!"}
                            description={"Multiplayer funfair minigames"}
                            year={"2015"}
                            onClick={() => this.PortfolioClicked(
                                "Magical Fun Park Online!",
                                [
                                    {src: magicalfunpark, altText: "", caption: "", type: "img"}
                                ],
                                "Multiplayer funfair minigames"
                            )}
                        />
                        <PortfolioItem
                            image={marchingdead}
                            active={this.state.active}
                            tags={["Games"]}
                            title={"The Marching Dead"}
                            description={"Survive waves of incoming zombies"}
                            year={"2013"}
                            onClick={() => this.PortfolioClicked(
                                "The Marching Dead",
                                [
                                    {src: marchingdead, altText: "", caption: "", type: "img"}
                                ],
                                "Survive waves of incoming zombies"
                            )}
                        />
                        <PortfolioItem
                            image={reachit}
                            active={this.state.active}
                            tags={["Games"]}
                            title={"Reachit!"}
                            description={"Jump on the blocks and reach the end without falling"}
                            year={"2013"}
                            onClick={() => this.PortfolioClicked(
                                "Reachit!",
                                [
                                    {src: reachit, altText: "", caption: "", type: "img"}
                                ],
                                "Jump on the blocks and reach the end without falling"
                            )}
                        />
                        <PortfolioItem
                            image={rollit}
                            active={this.state.active}
                            tags={["Games"]}
                            title={"Rollit!"}
                            description={"Roll the ball, collect the flags, and reach the end"}
                            year={"2013"}
                            onClick={() => this.PortfolioClicked(
                                "Rollit!",
                                [
                                    {src: rollit, altText: "", caption: "", type: "img"}
                                ],
                                "Roll the ball, collect the flags, and reach the end"
                            )}
                        />
                    </Row>
                </Container>
            </div>
        );
    }
}
