import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { BrowserRouter as Router, Route, Switch, Link, browserHistory } from 'react-router-dom';

import Home from './Home/Home';
import Header from './Header/Header';
import Footer from "./Footer/Footer";

function App() {
  return (
    <div className="App">
        <Header/>
        <Router>
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                {/*<Route path="/about">
                    <About />
                </Route>
                <Route path="/dashboard">
                    <Dashboard />
                </Route>*/}
            </Switch>
        </Router>
        <Footer/>
    </div>
  );
}

export default App;
