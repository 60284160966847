import React from 'react';
import styles from './Modal.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

export default class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={{display: this.props.visible?"block":"none"}}>
                <div style={{position: "fixed", left: "0px", right: "0px", top: "0px", bottom: "0px", backgroundColor: "rgba(0,0,0,0.5)", zIndex: 10}}>
                    <div style={{borderRadius: "5px", transform: "translate(-50%, -50%)", color: "black", position: "fixed", padding: "10px", left: "50%", top: "50%", width: "350px", maxWidth: "95%", backgroundColor: "white", zIndex: 11}}>
                        Test
                    </div>
                </div>
            </div>
        );
    }
}
