import React from 'react';
import styles from './Footer.module.scss';

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            year: ""
        };
    }

    componentDidMount() {
        this.setState({year: new Date().getFullYear()});
    }

    render() {
        return (
            <div style={{textAlign: "center", marginTop: "50px", marginBottom: "10px"}}>
                <small>&copy; Copyright {this.state.year}, Alexandros Kalogerakis</small>
            </div>
        );
    }
}
