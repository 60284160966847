import React from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';

export default class PortfolioCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            animating: false,
            interval: 3000
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.visible != this.props.visible){
            if(this.props.media[0].type == "video"){
                this.setState({activeIndex: 0, animating: false, interval: false});
            }
            else{
                this.setState({activeIndex: 0, animating: false, interval: 3000});
            }
        }
    }

    next = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === this.props.media.length - 1 ? 0 : this.state.activeIndex + 1;
        if(this.props.media[nextIndex] && this.props.media[nextIndex].type == "video"){
            this.setState({activeIndex: nextIndex, interval: false});
        }
        else{
            this.setState({activeIndex: nextIndex, interval: 3000});
        }
    }

    previous = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.media.length - 1 : this.state.activeIndex - 1;
        if(this.props.media[nextIndex] && this.props.media[nextIndex].type == "video"){
            this.setState({activeIndex: nextIndex, interval: false});
        }
        else{
            this.setState({activeIndex: nextIndex, interval: 3000});
        }
    }

    goToIndex = (newIndex) => {
        if (this.state.animating) return;
        if(this.props.media[newIndex] && this.props.media[newIndex].type == "video"){
            this.setState({activeIndex: newIndex, interval: false});
        }
        else{
            this.setState({activeIndex: newIndex, interval: 3000});
        }
    }

    render() {
        return (
            <div style={{width: "100%", height: "100%"}}>
                <Carousel
                    //pause={true}
                    interval={this.state.interval}
                    activeIndex={this.state.activeIndex}
                    next={this.next}
                    previous={this.previous}
                >
                    <CarouselIndicators items={this.props.media} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} />
                    {
                        this.props.media.map((item) => {
                            let elem;
                            if(item.type == "img"){
                                elem = <img src={item.src} alt={item.altText} style={{width: "100%", height: "100%", objectFit: "contain"}}/>;
                            }
                            else{
                                elem = <video autoPlay muted loop src={item.src} alt={item.altText} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                            }
                            return (
                                <CarouselItem
                                    onExiting={() => this.setState({animating: true})}
                                    onExited={() => this.setState({animating: false})}
                                    key={item.src}
                                >
                                    {elem}
                                    <CarouselCaption captionText={item.caption} captionHeader={item.altText} />
                                </CarouselItem>
                            );
                        })
                    }
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => this.previous()} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={() => this.next()} />
                </Carousel>
            </div>
        );
    }
}
