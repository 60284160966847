import React from 'react';
import styles from './Welcome.module.scss';
import logo from "../images/me_bg.png";

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            age: ""
        };
    }

    componentDidMount() {
        let ageDifference = Date.now() - new Date(1998, 3, 16).getTime();
        let ageDate = new Date(ageDifference);
        this.setState({age: Math.abs(ageDate.getFullYear() - 1970) });
    }

    render() {
        return (
            <div>
                <img src={logo} className={"avatar zoom"}/>
                <div style={{marginTop: "50px"}}>
                    <h3 style={{fontWeight: "normal", textTransform: "uppercase"}}>
                        Hi, I am Alexandros Kalogerakis
                    </h3>
                    <h1>
                        &lt;A passionate Web, App, Game<br/>Developer /&gt;
                    </h1>
                    <h3 style={{marginTop: "50px", fontWeight: "normal"}}>
                        I am {this.state.age} years old from Greece. Ever since I was a child, I was always very fascinated about programming.<br/>
                        I started as a self-taught programmer at the age of 14, and I'm a fast-learner.
                    </h3>
                </div>
            </div>
        );
    }
}
