import React from 'react';
import styles from './Contact.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import axios from "axios";
import Modal from "../Modal/Modal";

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            validName: true,
            validEmail: true,
            modalSuccess: false,
            modalMissing: false,
            modalError: false
        };

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeMessage = this.onChangeMessage.bind(this);
    }

    componentDidMount() {

    }

    onChangeName(e){
        this.setState({name: e.target.value});
    }

    onChangeEmail(e){
        this.setState({email: e.target.value});
    }

    onChangeMessage(e){
        this.setState({message: e.target.value});
    }

    Submit(){
        let valid = true;
        this.setState({validName: true, validEmail: true});
        if(this.state.name.trim() == ""){
            this.setState({validName: false});
            valid = false;
        }
        if(this.state.email.trim() == ""){
            this.setState({validEmail: false});
            valid = false;
        }
        else {
            if (/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(this.state.email)) {

            }
            else{
                this.setState({validEmail: false});
                valid = false;
            }
        }
        if(valid){
            var params = {
                name: this.state.name,
                email: this.state.email,
                message: this.state.message
            }

            axios.post("https://alexandros-kalogerakis.com/api/contact.php", params).then((response) => {
                if(response.data == "1"){
                    this.setState({modalSuccess: true, modalMissing: false, modalError: false});
                }
                else if(response.data == "0"){
                    this.setState({modalSuccess: false, modalMissing: true, modalError: false});
                }
                else{
                    this.setState({modalSuccess: false, modalMissing: false, modalError: true});
                }
            });
        }
    }

    render() {
        return (
            <div style={{width: "100%"}}>

                <div style={{display: this.state.modalSuccess?"block":"none"}}>
                    <div style={{position: "fixed", left: "0px", right: "0px", top: "0px", bottom: "0px", backgroundColor: "rgba(0,0,0,0.5)", zIndex: 10}}>
                        <div style={{textAlign: "center", borderRadius: "5px", transform: "translate(-50%, -50%)", color: "black", position: "fixed", padding: "15px 30px", left: "50%", top: "50%", width: "450px", maxWidth: "95%", backgroundColor: "white", zIndex: 11}}>
                            <div style={{color: "green"}}>
                                <big><b>Success</b></big>
                            </div>
                            <hr/>
                            <div>
                                I have successfully received your message. I will reply to your email in the next 24 hours.<br/><br/>If I don't reply, please try contacting me directly at my email address at<br/>alexkalog98 at gmail.com
                            </div>
                            <div onClick={()=>this.setState({modalSuccess: false})} style={{marginTop: "15px", padding: "3px 13px", cursor: "pointer", border: "1px solid black", borderRadius: "5px", display: "inline-block"}}>
                                Close
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{display: this.state.modalMissing?"block":"none"}}>
                    <div style={{position: "fixed", left: "0px", right: "0px", top: "0px", bottom: "0px", backgroundColor: "rgba(0,0,0,0.5)", zIndex: 10}}>
                        <div style={{textAlign: "center", borderRadius: "5px", transform: "translate(-50%, -50%)", color: "black", position: "fixed", padding: "15px 30px", left: "50%", top: "50%", width: "450px", maxWidth: "95%", backgroundColor: "white", zIndex: 11}}>
                            <div style={{color: "#ffc107"}}>
                                <big><b>Warning</b></big>
                            </div>
                            <hr/>
                            <div>
                                Please fill in your name and enter a valid email address
                            </div>
                            <div onClick={()=>this.setState({modalMissing: false})} style={{marginTop: "15px", padding: "3px 13px", cursor: "pointer", border: "1px solid black", borderRadius: "5px", display: "inline-block"}}>
                                Close
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{display: this.state.modalError?"block":"none"}}>
                    <div style={{position: "fixed", left: "0px", right: "0px", top: "0px", bottom: "0px", backgroundColor: "rgba(0,0,0,0.5)", zIndex: 10}}>
                        <div style={{textAlign: "center", borderRadius: "5px", transform: "translate(-50%, -50%)", color: "black", position: "fixed", padding: "15px 30px", left: "50%", top: "50%", width: "450px", maxWidth: "95%", backgroundColor: "white", zIndex: 11}}>
                            <div style={{color: "red"}}>
                                <big><b>Error</b></big>
                            </div>
                            <hr/>
                            <div>
                                There was an error, please try again later, or contact me directly at my email address at<br/>alexkalog98 at gmail.com
                            </div>
                            <div onClick={()=>this.setState({modalError: false})} style={{marginTop: "15px", padding: "3px 13px", cursor: "pointer", border: "1px solid black", borderRadius: "5px", display: "inline-block"}}>
                                Close
                            </div>
                        </div>
                    </div>
                </div>
                <Container>
                    <h1 style={{marginBottom: "20px"}}>
                        Contact
                    </h1>

                    <Row>
                        <Col md={6} style={{marginBottom: "30px"}}>
                            <input style={{border: this.state.validName?"":"1px solid red"}} type={"text"} value={this.state.name} onChange={this.onChangeName} className={styles.input} placeholder={"Your name (required)"}/>
                        </Col>
                        <Col md={6} style={{marginBottom: "30px"}}>
                            <input style={{border: this.state.validEmail?"":"1px solid red"}} type={"text"} value={this.state.email} onChange={this.onChangeEmail} className={styles.input} placeholder={"Your email (required)"}/>
                        </Col>
                        <Col md={12} style={{marginBottom: "30px"}}>
                            <textarea rows={2} value={this.state.message} onChange={this.onChangeMessage} type={"text"} className={styles.input} placeholder={"Your message"}/>
                        </Col>
                        <Col xs={12} style={{textAlign: "center"}}>
                            <div onClick={()=>this.Submit()} style={{cursor: "pointer", display: "inline-block", padding: "12px 15px", border: "1px solid white", borderRadius: "5px"}}>
                                Contact me!
                            </div>
                            <br/>
                            <br/>
                            <small>
                                Or send me an email at<br/>alexkalog98 at gmail.com
                            </small>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
