import React from 'react';
import styles from './Skills.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import {Col, Container, Row} from "reactstrap";

export default class Skill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            colVisible: true,
            titleVisible: false,
            colHeight: ""
        };
    }

    componentDidMount() {
        this.setState({colVisible: this.props.visible});
        this.setState({titleVisible: this.props.visible});
        this.setState({colHeight: this.props.visible?"64px":"0px"});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible !== this.props.visible) {
            if(this.props.visible){

                this.setState({colVisible: true});
                setTimeout(()=>{
                    this.setState({titleVisible: true});
                    this.setState({colHeight: "64px"});
                },1);
                setTimeout(()=>{
                    //this.setState({colVisible: true});
                },300);
            }
            else{

                setTimeout(()=>{
                    this.setState({titleVisible: false});
                    this.setState({colHeight: "0px"});
                },1);
                setTimeout(()=>{
                    this.setState({colVisible: false});
                },300);
            }
        }
    }

    render() {
        return (
            <Col md={6} style={{display: this.state.colVisible?"block":"none", transition: "all 0.3s ease-in-out", height: this.state.colHeight, opacity: this.state.titleVisible?"1":"0"}}>
                <div style={{pointerEvents: "none", transition: "all 0.3s linear", display: this.state.colVisible?"block":"block"}}>
                    {this.props.title}
                </div>
                <div style={{transition: "all 0.3s linear", marginBottom: "20px", backgroundColor: "#505050", width: "100%", height: this.props.visible?"20px":"0px", position: "relative", borderRadius: "3px"}}>
                    <div style={{position: "absolute", top: "0px", left: "0px", backgroundColor: "#f99429", borderRadius: "3px", width: this.props.percentage+"%", bottom: "0px"}}>

                    </div>
                </div>
            </Col>
        );
    }
}
